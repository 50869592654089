import Selector from '@images/logos-partners/Selector-Software-logo.svg';
import Neptune from '@images/logos-partners/Neptune-logo.svg';
import Mesh7 from '@images/logos-partners/Mesh7-logo.svg';

export const testimonials = [
  {
    quote:
      'CodiLime with its expertise in both network engineering and software development helped us build and release a platform for operational intelligence of digital infrastructure. CodiLime UX designers and software engineers did a great job to ensure great user experience of our product. Smooth communication, out-of-the-box thinking and reliability are those factors that also make our cooperation great. ',
    author: 'Rudra Rugge',
    company: 'VP of Engineering - Selector Software, Inc.',
    logo: Selector,
    logoAlt: 'Selector logo',
  },
  {
    quote:
      'CodiLime’s expertise in software engineering was indispensable at the beginning of Neptune’s journey. CodiLime helped us build and release the first version of Neptune and convince investors that our product is a real game changer for data scientists. Without any hesitation, I can recommend CodiLime as a reliable technology partner for every tech startup.',
    author: 'Piotr Niedźwiedź',
    company: 'Co-Founder & CEO - Neptune Labs, Inc.',
    logo: Neptune,
    logoAlt: 'Neptune logo',
    customClasses: 'neptune_logo',
  },
  {
    quote:
      'Thanks to its expertise in networking technologies and Kubernetes, CodiLime has become our reliable technical partner helping us deliver the first-in-class product for monitoring security in microservices. CodiLime’s experienced project managers and engineers played an important role in releasing the final product and thus keeping promises given to clients and investors.',
    author: 'Amit Jain',
    company: 'Co-Founder & CTO - Mesh7, Inc.',
    logo: Mesh7,
    logoAlt: 'Mesh7 logo',
    customClasses: 'lf_logo',
  },
];
