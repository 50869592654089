import React from 'react';

import { Button } from '@commons/button/Button';
import { Link } from '@commons/link/Link';
import { ListRender } from '@commons/list-render/ListRender';

import './single-box-decriptive.scss';
import './single-box-decriptive.mobile.scss';

export const SingleBoxDescriptive = ({
  src,
  alt,
  title,
  textContent,
  href,
  className = '',
  readMoreAnchor,
  listItem,
  subtitle,
  classNames: { description } = {},
  ...rest
}) => {
  const boxContent = textContent || (listItem && <ListRender data={listItem} withArrows lime />);

  return (
    <div className={`single_box single_box_descriptive ${className}`} {...rest}>
      {src && (
        <div className="box_image">
          <img loading="lazy" className="lazy" src={src} alt={alt} />
        </div>
      )}
      <div className={`box_description ${description}`}>
        {title && <h3>{title}</h3>}
        {subtitle}
        {boxContent}
      </div>
      {href && <Button href={href} label="Read more" className="single_box_button" />}

      {readMoreAnchor && (
        <Link href={readMoreAnchor} className="read-more-anchor">
          Read more &#x2192;
        </Link>
      )}
    </div>
  );
};
