import React from 'react';

import { Button } from '@commons/button/Button';

import * as styles from './let-us-know.module.scss';

export function LetUsKnowMvp() {
  return (
    <div className={styles.container}>
      <h3>Want to build an MVP for your software product?</h3>
      <Button label="We can help!" href="/mvp/#contact" />
    </div>
  );
}
