import React from 'react';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { TestimonialSlider } from '@commons/testimonial-slider/TestimonialSlider';

import { testimonials } from './payload';

import './mvp-testimonials.module.scss';

export const MvpTestimonials = ({ title, classNames, className, id = 'our-clients-about-us' }) => {
  return (
    <StandardSection className={className} title={title} classNames={classNames} id={id}>
      <TestimonialSlider testimonials={testimonials} />
    </StandardSection>
  );
};

MvpTestimonials.defaultProps = {
  title: 'See what our clients say about us',
};
