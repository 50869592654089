import mVPDevelopment from '@images/mvp-for-startups/MVP-Development.png';

import mVPDemonstration from '@images/mvp-for-startups/MVP-Demonstration.png';

import mVPInvestors from '@images/mvp-for-startups/MVP-Investors.png';

export const whyUs = [
  {
    src: mVPDevelopment,
    alt: 'MVP-Development',
    textContent: 'has the potential for further development',
  },
  {
    src: mVPDemonstration,
    alt: 'MVP-Demonstration',
    textContent: 'is a real-life demonstration that your idea works',
  },
  {
    src: mVPInvestors,
    alt: 'MVP-Investors',
    textContent: 'is a compelling opportunity for investors',
  },
];
