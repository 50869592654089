import React from 'react';
import HubspotForm from 'react-hubspot-form';

import { appendJquery } from '@src/utils/appendJquery';
import { downloadPdfFile } from '@src/utils/dowloadPdfFile';

import { LoadingSpinner } from '@commons/loading-spinner/LoadingSpinner';

import whitePaperPDF from './MVP+for+startups+-+CodiLime+white+paper.pdf';

export function DownloadFormMVP() {
  return (
    <div className="old-hubspot-form">
      <HubspotForm
        region="eu1"
        portalId="24990721"
        formId="3b80bed7-7aef-47aa-8589-7b4e0bf1ce07"
        loading={<LoadingSpinner />}
        onReady={() => appendJquery()}
        onSubmit={() => undefined}
        onFormSubmitted={() => {
          downloadPdfFile(whitePaperPDF);
        }}
      />
    </div>
  );
}
