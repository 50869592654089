import React, { useState } from 'react';

import { Button } from '@commons/button/Button';
import { ModalPortal } from '@commons/modal-portal/ModalPortal';
import { StandardSection } from '@commons/standard-section/StandardSection';
import { ListRender } from '@commons/list-render/ListRender';

import { DownloadPopup } from './DownloadPopup';

import downloadImage from './download_image.svg';

import * as styles from './download-white-paper.module.scss';

export const DownloadWhitePaper = () => {
  const [showForm, setShowForm] = useState(false);
  return (
    <>
      <StandardSection id="mvp_download" className={styles.section} title="MVP—from idea to release">
        <div className={styles.container}>
          <div>
            <p>Download our comprehensive guide to see:</p>
            <ListRender data={['MVP development stages', 'Technology stack', 'Case studies']} withArrows lime />
            <Button onClick={() => setShowForm(true)} label="Download" />
          </div>
          <div>
            <img loading="lazy" src={downloadImage} alt="Download White Paper" />
          </div>
        </div>
      </StandardSection>
      <ModalPortal>
        <DownloadPopup showModal={showForm} setShowModal={setShowForm} />
      </ModalPortal>
    </>
  );
};
