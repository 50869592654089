import React from 'react';

import * as styles from './video-container.module.scss';

export const VideoContainer = ({ videoSrc, title }) => (
  <div className={styles.videoContainer}>
    {title && <div className={styles.videoTitle}>{title}</div>}
    <div className={styles.videoWrapper}>
      <iframe
        width="923"
        height="519"
        src={videoSrc}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  </div>
);
