import { useEffect, useState } from 'react';
import { isOnScreen } from '@src/utils/calculations';

export const useOffset = (ref) => {
  const [offset, setOffset] = useState(0);
  const [visibleOnScreen, setVisibleOnScreen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const { top, bottom } = ref.current.getBoundingClientRect();
      const isVisible = isOnScreen(top, bottom);
      setVisibleOnScreen(isVisible);
      isVisible && setOffset(window.pageYOffset);
    };
    handleScroll && window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return { offset, visibleOnScreen };
};
