import React, { createContext } from 'react';
import { useOffset } from '@hooks/useOffset';

export const OffsetContext = createContext(undefined);

export const OffsetProvider = ({ children }) => {
  const offset = useOffset(children.ref);

  return <OffsetContext.Provider value={offset}>{children}</OffsetContext.Provider>;
};
