const defaultConfig = {
  type: 'fade',
  delay: '200',
  duration: '400',
  easing: 'ease',
};

export const easeInAttributes = {
  ['data-sal']: defaultConfig.type,
  ['data-sal-delay']: defaultConfig.delay,
  ['data-sal-duration']: defaultConfig.duration,
  ['data-sal-easing']: defaultConfig.easing,
};
