import React from 'react';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { SingleBoxDescriptive } from '@commons/single-box-descriptive/SingleBoxDescriptive';
import { easeInAttributes } from '@commons/ease-in-on-scroll/EaseInOnScroll';

import { whyUs } from './payload';

import * as styles from './why-build-a-mvp.module.scss';

export const WhyBuildAnMVP = () => {
  return (
    <StandardSection id="what_is_mvp" title="Why build an MVP">
      <p>
        <strong>A Minimum Viable Product (MVP)</strong> is a version of a software product that has enough
        functionalities to satisfy the needs of early adopters and show investors that your ideas can bring business
        benefits in real-life scenarios. A successfully developed MVP:
      </p>
      <div className={styles.boxesContainer}>
        {whyUs.map((box, i) => {
          return <SingleBoxDescriptive key={i} {...box} {...easeInAttributes} />;
        })}
      </div>
      <p>
        An integral part of an MVP is <strong>a clickable UX prototype</strong> that will showcase your idea to
        potential investors. It may be a standalone project or a part of the entire MVP development process.
      </p>
    </StandardSection>
  );
};
