import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import { Header } from '@commons/header/Header';

import {
  LetUsKnowMvp,
  WhyBuildAnMVP,
  PowerOfMinimumVideo,
  HowCodilimeCanHelp,
  MvpTestimonials,
  DownloadWhitePaper,
} from '@pages-impl/mvp-for-startups';

import featuredImage from '@images/mvp-for-startups/featured_image_ci_cd_lp.jpg';

import * as styles from './mvp-for-startups.module.scss';

export default function Index(props) {
  return (
    <Layout layout="landing" {...props} contactFormType={false}>
      <SEO
        title="Build an MVP for your software product - CodiLime"
        featuredImage={featuredImage}
        description="Minimum Viable Product(MVP) is a version of a software product that has enough functionalities to show that your ideas work. Download our whitepaper to know more."
      />
      <Header
        src="mvp_header_bg.jpg"
        title="Release your product seamlessly and disrupt the market"
        logoContainerWhite={true}
        displayTopBanner={false}
        className={styles.header}
      >
        <LetUsKnowMvp />
      </Header>
      <WhyBuildAnMVP />
      <HowCodilimeCanHelp />
      <MvpTestimonials />
      <DownloadWhitePaper />
      <PowerOfMinimumVideo />
    </Layout>
  );
}
