import React, { useRef, useContext } from 'react';

import { isFunction } from 'lodash';
import cx from 'classnames';

import { OffsetProvider } from '@context/OffsetContext';

import { Parallax } from '@commons/parallax/Parallax';
import { FadingOnScroll } from '@commons/fading-on-scroll/FadingOnScroll';
import { Link } from '@commons/link/Link';
import { paths } from '@src/constants/pathsEnum';

import codilimeLogoWhite from '@images/logo_footer.svg';
import codilimeLogoDark from '@images/codilime-logo/CodiLime_logo.svg';

import Images from './Images';

import * as styles from './header.module.scss';
import { TopBannerContext } from '@context/TopBannerContext';

const renderTitleDescription = (descriptionTitle) =>
  isFunction(descriptionTitle) ? descriptionTitle() : <h1>{descriptionTitle}</h1>;

const renderTitleDescriptionH3 = (descriptionTitle) =>
  isFunction(descriptionTitle) ? descriptionTitle() : <h3>{descriptionTitle}</h3>;

// TODO: Verify data-type attribute inside img;
export function Header({
  title,
  titleLime,
  aboveTitle = null,
  descriptionTitle,
  descriptionText,
  id,
  src,
  srcSmall,
  srcSVG,
  hasImage = true,
  showImageOnMobile = false,
  className = '',
  logoContainerClassName = undefined,
  headerMainContentClassName = undefined,
  headerOuterWrapper = undefined,
  children,
  headerFigures,
  logoContainerWhite,
  logoContainerDark,
  headerTitleH1 = false,
  descriptionTitleH3 = false,
  displayTopBanner = true,
}) {
  const ref = useRef();
  const { isBannerHidden } = useContext(TopBannerContext);
  const homeTitle = (
    <>
      <h1 className="header-title">
        {title}
        <br />
        <span className={styles.titleLime}>{titleLime}</span>
      </h1>
    </>
  );
  const titleElement = titleLime ? (
    homeTitle
  ) : descriptionTitle && !headerTitleH1 ? (
    <>
      {aboveTitle && <h3 className={styles.additionalTitle}>{aboveTitle}</h3>}
      <h2 className="header-title">{title}</h2>
    </>
  ) : (
    <>
      {aboveTitle && <h3 className={styles.additionalTitle}>{aboveTitle}</h3>}

      <h1 className="header-title">{title}</h1>
    </>
  );

  const headerMainContent = (
    <>
      {titleElement}
      <div className="header-description">
        {!descriptionTitleH3
          ? descriptionTitle && renderTitleDescription(descriptionTitle)
          : descriptionTitle && renderTitleDescriptionH3(descriptionTitle)}
        {descriptionText && <p>{descriptionText}</p>}
      </div>
    </>
  );
  function HeaderBckgImage() {
    {
      if (srcSVG) {
        if (!showImageOnMobile) {
          return <img loading="lazy" src={src} className={styles.heroImage} alt="Header Background Image" />;
        } else {
          return (
            <>
              <img loading="lazy" src={src} className={styles.heroImage} alt="Header Background Image" />
              <Images filename={srcSmall || src} className={styles.heroImageSmall} alt="Header Background Image" />
            </>
          );
        }
      } else {
        return (
          <>
            <Images filename={src} className={styles.heroImage} alt="Header Background Image" />
            {showImageOnMobile && (
              <Images filename={srcSmall || src} className={styles.heroImageSmall} alt="Header Background Image" />
            )}
          </>
        );
      }
    }
  }

  return (
    <OffsetProvider>
      <header
        ref={ref}
        id={id}
        className={cx('header', className, !isBannerHidden && displayTopBanner && 'top-banner-margin')}
      >
        <Parallax factor={0.22}>{hasImage ? <HeaderBckgImage /> : null}</Parallax>
        {headerFigures && <div className="header_figures" />}
        <FadingOnScroll isLongHeader>
          <div className={cx(styles.outerWrapper, headerOuterWrapper)}>
            {logoContainerWhite && (
              <div className={cx(styles.logoContainerHeader, logoContainerClassName)}>
                <Link href={paths.HOME}>
                  <img
                    loading="lazy"
                    src={codilimeLogoWhite}
                    alt="Codilime - Network and software engineering services company"
                  />
                </Link>
              </div>
            )}
            {logoContainerDark && (
              <div className={cx(styles.logoContainerHeader, logoContainerClassName)}>
                <Link href={paths.HOME}>
                  <img
                    loading="lazy"
                    src={codilimeLogoDark}
                    alt="Codilime - Network and software engineering services company"
                  />
                </Link>
              </div>
            )}
            {!logoContainerWhite && !logoContainerDark && null}
            <div className={cx('header-main-content', headerMainContentClassName)} data-depth="0.00" data-text="true">
              <div className="header-inner-wrapper">
                {children ? (
                  <div className={styles.childrenContainer}>
                    <div className={styles.leftSide}>{headerMainContent}</div>
                    <div className={styles.rightSide}>{children}</div>
                  </div>
                ) : (
                  headerMainContent
                )}
              </div>
            </div>
          </div>
        </FadingOnScroll>
      </header>
    </OffsetProvider>
  );
}
