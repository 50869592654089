import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { ListRender } from '@commons/list-render/ListRender';

import { codilimeHelp } from './payload';

import * as styles from './how-codilime-can-help.module.scss';

export const HowCodilimeCanHelp = () => {
  return (
    <StandardSection id="how_codilime_can_help" className={styles.section} title="How CodiLime can help">
      <div className={styles.container}>
        <div>
          <ListRender data={codilimeHelp} withArrows lime />
        </div>
        <div>
          <StaticImage src="./How_codilime_can_help.png" alt="How CodiLime can help" />
        </div>
      </div>
    </StandardSection>
  );
};
