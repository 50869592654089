import React from 'react';

import { VideoContainer } from '@commons/video-container/videoContainer';
import { StandardSection } from '@commons/standard-section/StandardSection';

import * as styles from './video-section.module.scss';

const videoProps = {
  videoSrc: 'https://www.youtube.com/embed/AdDqhwlS6gQ',
  title: '',
};

export const PowerOfMinimumVideo = () => {
  return (
    <StandardSection id="the_power_of" className={styles.section} title="The power of Minimum Viable Product">
      <VideoContainer {...videoProps} />
    </StandardSection>
  );
};
