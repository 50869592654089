import React, { cloneElement, useContext } from 'react';

import { OffsetContext } from '@context/OffsetContext';

//TODO add safer nested data accessing
export const Parallax = ({ children, factor }) => {
  const { offset } = useContext(OffsetContext);
  const translateY = `translateY(${offset * factor}px)`;
  const transform = {
    transform: translateY,
  };
  const style = children?.props?.style
    ? {
        ...children.props.style,
        ...transform,
      }
    : transform;

  return children ? cloneElement(children, { style: style }) : <></>;
};
