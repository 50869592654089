import React, { cloneElement, useContext } from 'react';
import { OffsetContext } from '@context/OffsetContext';

//TODO add safer nested data accessing
export const FadingOnScroll = ({ children, isLongHeader }) => {
  const { offset } = useContext(OffsetContext);

  let headerDividerValue;
  isLongHeader ? (headerDividerValue = 400) : 50;

  const opacity = {
    opacity: 50 / offset > 1 ? 1 : headerDividerValue / offset,
  };
  const _style = children?.props?.style
    ? {
        ...children.props.style,
        ...opacity,
      }
    : opacity;
  return children ? cloneElement(children, { style: _style }) : <></>;
};
