import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import Image from '../image/Image';

const Images = (props) => {
  const { images } = useStaticQuery(graphql`
    query {
      images: allFile(filter: { relativeDirectory: { eq: "header-images" } }) {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const image = images.edges.find((n) => {
    return n.node.relativePath.includes(props.filename);
  });

  if (!image) return null;
  return (
    <Image
      className={cx('pageHeroImage', props.className)}
      image={image.node}
      alt={props.alt}
      style={{ position: 'absolute' }}
    />
  );
};

export default Images;
