import React from 'react';

import { Overlay } from '@commons/overlay/Overlay';

import { DownloadFormMVP } from './DownloadFormMVP';

import closeIcon from '@images/close-icon.svg';

import * as styles from './download-popup.module.scss';

const DownloadPopup = ({ showModal, setShowModal }) => {
  function closeForm(e) {
    e.preventDefault();
    setShowModal(false);
  }

  return (
    <>
      {showModal && (
        <>
          <Overlay />
          <div className={styles.container}>
            <button className={styles.closeBtn} onClick={closeForm}>
              <img loading="lazy" src={closeIcon} alt="Close icon" />
            </button>
            <div className={styles.formContainer}>
              <h3 className={styles.formTitle}>MVP—from idea to release</h3>
              <DownloadFormMVP />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { DownloadPopup };
